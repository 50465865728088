.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.closeButton{
    float: right;
    margin: 5px 10px 0 0;
    cursor: pointer;
}

.modal-main {
    border-radius: 5px;
    position:fixed;
    background: white;
    min-width: 220px;
    max-width: 520px;
    /* width: 50%; */
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.modal-main .content{
    padding: 30px 20px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-main button {
    /* font-size: 1em; */
    padding: 10px 30px;
    border-radius: 5px;
    background-color: darkcyan;
    border: 2px solid white;
    color: white;
    font-weight: 600;
    margin-bottom: 15px;
}

.modal-main button:hover {
    padding: 10px 30px;
    border-radius: 5px;
    color: darkcyan;
    border: 2px solid darkcyan;
    background-color: white;
    font-weight: 600;
}